<template>
  <b-container>
  </b-container>
</template>
<script>
export default {
  created () {
    if (this.$route.query.token) {
      localStorage.setItem('userToken', this.$route.query.token)
      this.$store.commit('loginStatus', true)
      this.$router.push('/')
    }
  }
}
</script>
